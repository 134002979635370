import store from '@/store'
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import * as api from '@/api/meeting'
import MeetingInfo from '@/store/entities/meeting-info'

@Module({ dynamic: true, store, name: "meeting", namespaced: true })
class MeetingStore extends VuexModule {
    public currentInfo: MeetingInfo = new MeetingInfo();
    public allRoomList: Array<any> = new Array<any>();
    public subMeetingList: Array<any> = new Array<any>();

    public list = [];
    public totalCount = 0;
    public currentPage = 1;
    public pageSize = 10;

    @Mutation
    public setSubMeetingList(data: any) {
        this.subMeetingList = data;
    }

    @Mutation
    public setCurrentInfo(data: any) {
        this.currentInfo = data;
        this.currentInfo.relationRooms = data.rooms;
    }

    @Mutation
    public setAllRoomList(data: any) {
        this.allRoomList = data;
    }

    @Mutation
    private setList(data: any) {
        this.list = data.items as [];
        this.totalCount = data.totalCount;
    }

    @Mutation
    public setPage(page: number) {
        this.currentPage = page;
    }

    @Mutation
    public setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }

    @Action
    public async getAllRoomByOrgId(params: any) {
        let result = await api.getAllRoomByOrgId(params);
        return result.data.result;
    }

    @Action({ commit: "setAllRoomList" })
    public async getAllRoomList(params: any) {
        let result = await api.getAllRoomList(params);
        return result.data.result;
    }

    @Action({ commit: "setCurrentInfo" })
    public async get(params: any) {
        let result = await api.get(params);
        return result.data.result;
    }

    @Action({ commit: "setList" })
    public async getAll(params: any) {
        params.maxResultCount = this.pageSize;
        params.skipCount = (this.currentPage - 1) * this.pageSize;

        let result = await api.getAll(params);
        return result.data.result;
    }

    @Action
    public async getRoomById(params: any) {
        let result = await api.getRoomById(params);
        return result.data.result;
    }

    @Action
    public async getAllRoomByPage(params: any) {
        let result = await api.getAllRoomByPage(params);
        return result.data.result;
    }

    @Action
    public async getAvailableRoomList(params: any) {
        let result = await api.getAvailableRoomList(params);
        return result.data.result;
    }

    @Action
    public async create(data: any) {
        let result = await api.create(data);
        return result.data.result;
    }

    @Action
    public async createCycleMeeting(data: any) {
        let result = await api.createCycleMeeting(data);
        return result.data.result;
    }

    @Action
    public async getSameDayEvents(params: any) {
        let result = await api.getSameDayEvents(params);
        return result.data.result;
    }

    @Action
    public async cancelMeeting(data: any) {
        let result = await api.cancelMeeting(data);
        return result.data.result;
    }

    @Action
    public async submitDraft(data: any) {
        let result = await api.submitDraft(data);
        return result.data.result;
    }

    @Action
    public async editMeetingRoom(data: any) {
        let result = await api.editMeetingRoom(data);
        return result.data.result;
    }

    @Action
    public async toggleRoomActive(params: any) {
        let result = await api.toggleRoomActive(params);
        return result.data.result;
    }

    @Action
    public async atTheSameTime(params: any) {
        let result = await api.atTheSameTime(params);
        return result.data.result;
    }

    @Action
    public async edit(data: any) {
        let result = await api.edit(data);
        return result.data.result;
    }

    @Action
    public async finishMeeting(params: any) {
        let result = await api.finishMeeting(params);
        return result.data.result;
    }

    @Action({ commit: "setSubMeetingList" })
    public async getSubMeetingById(params: any) {
        let result = await api.getSubMeetingById(params);
        return result.data.result;
    }

    @Action
    public async cancelAllSubMeeting(params: any) {
        let result = await api.cancelAllSubMeeting(params);
        return result.data.result;
    }

    @Action
    public async adjustParticipants(data: any) {
        let result = await api.adjustParticipants(data);
        return result.data.result;
    }
}

export default getModule(MeetingStore);