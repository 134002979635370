var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"visible":_vm.show,"top":"3vh","width":"60%","title":"选择主持人","close-on-click-modal":false,"close-on-press-escape":false},on:{"close":_vm.close}},[_c('el-autocomplete',{staticStyle:{"width":"100%"},attrs:{"suffix-icon":"el-icon-search","placeholder":"根据员工姓名、员工号、部门名称模糊搜索","fetch-suggestions":_vm.employeeSearch,"highlight-first-item":true,"trigger-on-focus":false,"select-when-unmatched":false},on:{"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('i',{staticClass:"el-icon-user"}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(item.employeeName))]),_c('span',{staticStyle:{"color":"darkgray","margin-left":"10px"}},[_vm._v(_vm._s(item.positionName))])])}}]),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"常用人员","name":"first"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.oftenList,"stripe":false,"show-header":false},on:{"row-click":_vm.tableRowClick}},[_c('el-table-column',{attrs:{"prop":"employeeName","label":"姓名","width":"130"}}),_c('el-table-column',{attrs:{"label":"岗位"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"color":"darkgray"}},[_vm._v(_vm._s(row.positionName))])]}}])})],1)],1),_c('el-tab-pane',{attrs:{"label":"同部门","name":"second"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":false,"show-header":false},on:{"row-click":_vm.tableRowClick}},[_c('el-table-column',{attrs:{"prop":"employeeName","label":"姓名","width":"130"}}),_c('el-table-column',{attrs:{"label":"岗位"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"color":"darkgray"}},[_vm._v(_vm._s(scope.row.positionName))])]}}])})],1)],1),_c('el-tab-pane',{attrs:{"label":"组织机构","name":"third"}},[_c('el-tree',{attrs:{"accordion":"","lazy":"","highlight-current":"","props":_vm.defaultProps,"load":_vm.loadNode},on:{"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticClass:"custom-tree-node"},[(data.isEmployee)?_c('div',[_c('i',{staticClass:"el-icon-user"}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(node.label))]),_c('span',{staticStyle:{"color":"darkgray","margin-left":"15px"}},[_vm._v(_vm._s(data.positionName))])]):_c('span',[_vm._v(_vm._s(node.label))])])}}])})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v("取 消")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }