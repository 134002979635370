import axios from 'axios';
import Vue from 'vue';
let vm = new Vue({});
const ajax = axios.create({
    baseURL: "/", //process.env.VUE_APP_APIURL,
    timeout: 10000
});

/* 异常统一处理*/
ajax.interceptors.response.use(res => {
    return res;
}, error => {
    if (!error || !error.response) {
        vm.$message.error("网络错误，response is Empty");
    } else if (!error.response.data || !error.response.data.error) {
        vm.$message.error("response.data.error is Empty");
    } else if (!!error.response.data.error.details && error.response.data.error.details) {
        vm.$message.error(error.response.data.error.details);
    } else if (!!error.response.data.error.message && error.response.data.error.message && !!error.response.data.error.code && error.response.data.error.code > 10000) {  //自定义错误
        vm.$alert(error.response.data.error.message, '警告', { confirmButtonText: '确定', type: 'warning' });
    } else if (!!error.response.data.error.message && error.response.data.error.message) {
        vm.$notify.error({
            title: "温馨提示",
            message: error.response.data.error.message,
            duration: 5000
        });
    }
    else {
        vm.$message.error("nothing");
    }

    return Promise.reject(error);
});

export default ajax;