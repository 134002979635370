











































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseDataModule from '@/store/modules/base-data';
import MeetingModule from '@/store/modules/meeting';
import MeetingInfo from '@/store/entities/meeting-info';
import Anchor from '@/views/components/Anchor.vue';
import Collective from '@/views/components/Collective.vue';
import OrganInfo from '@/store/entities/organ-info';
import { StateEnum, MeetingTypeEnum } from '@/store/entities/state-enum';
import { datetimeFormat } from '@/util/date-util';
import CallBackStaff from '@/store/entities/callback-staff';

@Component({
  components: {
    Anchor,
    Collective
  }
})
export default class Reserve extends Vue {
  @Prop({ default: false }) isShow!: boolean;
  @Prop({ default: false }) isCopy!: boolean;
  @Prop({ type: Date }) startDt!: Date;
  @Prop({ type: Date }) endDt!: Date;

  searchLoading: boolean = false;
  attendeeIds: number[] = [];
  informersIds: number[] = [];
  searchList: CallBackStaff[] = [];
  searchRecordList: CallBackStaff[] = [];

  state: any = StateEnum;
  ruleForm: MeetingInfo = new MeetingInfo();
  availableRoomList: Array<any> = [];

  showAlert: boolean = false;
  showAnchor: boolean = false;
  showCollective: boolean = false;
  showInformer: boolean = false;
  arLoading: boolean = false;
  submitLoading: boolean = false;
  saveLoading: boolean = false;
  showCkJoinOrg: boolean = true;

  get orgList() {
    return BaseDataModule.orgList;
  }

  get currentUser() {
    return BaseDataModule.currentUser;
  }

  get meetingTypeList() {
    return BaseDataModule.meetingTypeList;
  }

  @Watch('ruleForm', { deep: true })
  fetchValue(newVal: MeetingInfo, oldVal: MeetingInfo) {
    if (newVal.name) {
      if (!window.localStorage) {
        this.$message.error('浏览器不支持localStorage');
      } else {
        let _d = JSON.stringify(this.ruleForm);
        localStorage.setItem('singleModel', _d);
      }
    }
  }

  async remoteMethod(q: string) {
    this.searchLoading = true;
    await BaseDataModule.getEmployeeListBySearch({ searchKeyword: q })
      .then((res) => {
        this.searchList = res.result.map((item: CallBackStaff) => {
          let o: CallBackStaff = {
            employeeID: item.employeeID,
            employeeName: item.employeeName,
            positionName: item.positionName
          };
          this.pushSearchRecordList(o);
          return o;
        });
      })
      .finally(() => {
        this.searchLoading = false;
      });
  }

  async save(status: StateEnum) {
    (this.$refs.ruleForm as any).validate((valid: boolean) => {
      if (valid) {
        /*判断会议开始时间不能大于会议结束时间*/
        if (this.ruleForm.beginTime > this.ruleForm.endTime) {
          this.$message.warning('会议开始时间不能大于会议结束时间');
          return;
        }

        /*判断视频会议必须选择2个以上会议室*/
        if (this.ruleForm.meetingType == MeetingTypeEnum.Video && this.ruleForm.ckIds.length < 2) {
          this.$message.error('视频会议必须选择2个或以上会议室');
          return;
        }

        if (this.ruleForm.attendees.length <= 1 && status != StateEnum.Draft) {
          this.$confirm('建议您完整选择本次会议的参会人，以便排查您召集的参会人是否存在日程冲突...', '提示', {
            confirmButtonText: '选择无误,继续提交',
            cancelButtonText: '我再想想',
            type: 'warning'
          })
            .then(() => {
              this.saveSpecificOperation(status);
            })
            .catch(() => {
              return;
            });
        } else {
          this.saveSpecificOperation(status);
        }
      }
    });
  }

  async saveSpecificOperation(status: StateEnum) {
    let _message = '';
    if (status == StateEnum.Draft) {
      this.saveLoading = true;
      _message = '确认保存草稿？';
    } else {
      this.submitLoading = true;
      _message = '确认提交审批？';
    }

    this.ruleForm.status = status;
    this.ruleForm.subscriberId = Number(this.currentUser.employeeID);
    this.ruleForm.subscriberName = this.currentUser.employeeName;
    this.ruleForm.approvalOrgName = (this.orgList.find((x) => x.id === this.ruleForm.approvalOrgID) as OrganInfo)?.shortName;
    this.ruleForm.relationRooms = this.availableRoomList
      .filter((x) => this.ruleForm.ckIds.indexOf(x.id) > -1)
      .map((t) => ({
        key: t.id,
        value: t.name
      }));

    this.$confirm(_message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(async () => {
        this.ruleForm.meetingMode = 1;
        await MeetingModule.atTheSameTime(this.ruleForm).then(async (res) => {
          if (res) {
            let remindMsg = `${res}在此时间段（${this.ruleForm.beginTime}~${this.ruleForm.endTime}）需要参加其他会议（时间冲突）,是否继续提交？`;
            this.$confirm(remindMsg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(async () => {
                await this.createMeeting();
              })
              .catch(() => {
                this.saveLoading = false;
                this.submitLoading = false;
              });
          } else {
            await this.createMeeting();
          }
        });
      })
      .catch(() => {
        this.saveLoading = false;
        this.submitLoading = false;
      });
  }

  async createMeeting() {
    await MeetingModule.create(this.ruleForm)
      .then((res) => {
        this.$message.success('操作成功');
        this.$emit('refreshData');
        this.clearLS();
        this.close();
      })
      .finally(() => {
        this.saveLoading = false;
        this.submitLoading = false;
      });
  }

  async selectAvailableRoom() {
    let _orgIds = this.ruleForm.joinOrgID.length <= 0 ? this.ruleForm.joinSingleOrgID : this.ruleForm.joinOrgID.join(',');
    if (this.ruleForm.meetingType <= 0 || !_orgIds || !this.ruleForm.singleDate) return;

    this.availableRoomList.splice(0);
    if (!!this.ruleForm.meetingType && !!this.ruleForm.singleDate && (this.ruleForm.joinOrgID.length > 0 || this.ruleForm.joinSingleOrgID > 0)) {
      this.arLoading = true;

      await MeetingModule.getAvailableRoomList({
        meetingType: this.ruleForm.meetingType,
        date: this.ruleForm.singleDate,
        organIds: _orgIds
      }).then((res) => {
        this.availableRoomList = res;
        setTimeout(() => {
          this.arLoading = false;
        }, 1000);
      });
    }
  }

  async open() {
    if (this.isCopy) {
      /*复制初始化*/
      Object.assign(this.ruleForm, MeetingModule.currentInfo);
      /**部分触发条件不复制*/
      this.ruleForm.id = 0;
      this.ruleForm.singleDate = '';
      this.ruleForm.beginTime = '';
      this.ruleForm.endTime = '';
      this.ruleForm.joinOrgID = [];
      this.ruleForm.joinSingleOrgID = 0;
      this.ruleForm.ckIds = [];
    } else {
      /*缓存初始化*/
      let _d = localStorage.getItem('singleModel');
      if (_d) {
        this.showAlert = true;
        this.ruleForm = JSON.parse(_d);
        this.selectAvailableRoom();
      } else this.showAlert = false;

      this.ruleForm.singleDate = datetimeFormat(this.startDt, 'yyyy-MM-dd');
      this.ruleForm.beginTime = datetimeFormat(this.startDt, 'hh:mm');
      this.ruleForm.endTime = datetimeFormat(this.endDt, 'hh:mm');
    }

    this.loadSelectedCache();
  }

  loadSelectedCache() {
    /*读取主持人缓存*/
    if (this.ruleForm.callerId) {
      let o = {
        employeeID: this.ruleForm.callerId,
        employeeName: this.ruleForm.callerName,
        positionName: this.ruleForm.positionName
      };
      this.pushSearchList(o);
      this.pushSearchRecordList(o);
    }

    /*读取参会人缓存*/
    if (this.ruleForm.attendees) {
      this.attendeeIds = this.ruleForm.attendees.map((t) => {
        return t.key;
      });

      this.ruleForm.attendees.forEach((x) => {
        let o = {
          employeeID: x.key,
          employeeName: x.value,
          positionName: x.positionName
        };
        this.pushSearchList(o);
        this.pushSearchRecordList(o);
      });
    }

    /*读取知会人缓存*/
    if (this.ruleForm.informers) {
      this.informersIds = this.ruleForm.informers.map((t) => {
        return t.key;
      });

      this.ruleForm.informers.forEach((x) => {
        let o = {
          employeeID: x.key,
          employeeName: x.value,
          positionName: x.positionName
        };
        this.pushSearchList(o);
        this.pushSearchRecordList(o);
      });
    }
  }

  mounted() {
    setTimeout(async () => {
      await BaseDataModule.getMeetingType();
      await BaseDataModule.getCycleRepeatMode();
      await BaseDataModule.getAllOrgansOfTheGroup();
      await BaseDataModule.getSameDeptStaffData({ deptId: this.currentUser.deptID });
      await BaseDataModule.getOftenUsedList({ subscriberId: this.currentUser.employeeID });
    }, 1000);
  }

  disableSingleDate(eachDate: Date) {
    let now = datetimeFormat(new Date()) + ' 00:00:00';
    let dNow = new Date(now);
    return eachDate < dNow;
  }

  clearLS() {
    localStorage.removeItem('singleModel');
    this.showAlert = false;
  }

  chjdChange(val: any) {
    this.availableRoomList.splice(0);
    this.selectAvailableRoom();
  }

  meetingTypeChange(val: any) {
    this.availableRoomList.splice(0);
    if (val == 1) {
      this.showCkJoinOrg = true;
      this.ruleForm.joinSingleOrgID = 0;
    } else {
      this.showCkJoinOrg = false;
      this.ruleForm.joinOrgID.splice(0);
    }

    this.selectAvailableRoom();
  }

  roomCkChange(val: any) {
    if (val.length > 0) {
      let lastId = val[val.length - 1];
      let orgId = this.availableRoomList.filter((x) => x.id == lastId)[0].organId;
      let ids = this.availableRoomList.filter((t) => t.organId == orgId && t.id != lastId).map((x) => x.id);

      ids.forEach((item) => {
        let _index = this.ruleForm.ckIds.indexOf(item);
        if (_index > -1) {
          this.ruleForm.ckIds.splice(_index, 1);
        }
      });
    }
  }

  selectDateClear() {
    if (!this.ruleForm.singleDate) {
      this.availableRoomList.splice(0);
    }
  }

  pushSearchList(item: CallBackStaff) {
    let _index = this.searchList.findIndex((x) => x.employeeID == item.employeeID);
    if (_index <= -1) {
      this.searchList.push(item);
    }
  }

  pushSearchRecordList(item: CallBackStaff) {
    let _index = this.searchRecordList.findIndex((x) => x.employeeID == item.employeeID);
    if (_index <= -1) {
      this.searchRecordList.push(item);
    }
  }

  anchorChange(val: any) {
    if (!val) return;

    let item = this.searchRecordList.find((x) => x.employeeID == val) as CallBackStaff;
    this.ruleForm.callerName = item.employeeName;
  }

  attendeeChange(val: number[]) {
    this.ruleForm.attendees.splice(0);
    let selectedAll = this.searchRecordList.filter((x) => val.indexOf(x.employeeID) > -1);
    this.ruleForm.attendees = selectedAll.map((x) => ({
      key: x.employeeID,
      value: x.employeeName,
      positionName: x.positionName
    }));
  }

  informerChange(val: number[]) {
    let selectedAll = this.searchRecordList.filter((x) => val.indexOf(x.employeeID) > -1);
    this.ruleForm.informers = selectedAll.map((x) => ({
      key: x.employeeID,
      value: x.employeeName,
      positionName: x.positionName
    }));
  }

  anchorCallback(value: CallBackStaff) {
    this.pushSearchList(value);
    this.pushSearchRecordList(value);

    this.ruleForm.callerId = value.employeeID;
    this.ruleForm.callerName = value.employeeName;
    this.ruleForm.positionName = value.positionName;
    (this.$refs.ruleForm as any).validateField('callerId');
  }

  attendeeCallback(value: CallBackStaff[]) {
    value.forEach((item) => {
      this.pushSearchList(item);
      this.pushSearchRecordList(item);

      if (this.attendeeIds.indexOf(item.employeeID) <= -1) {
        this.attendeeIds.push(item.employeeID);
        /*回调后触发change事件*/
        this.attendeeChange(this.attendeeIds);
      }
    });

    (this.$refs.ruleForm as any).validateField('attendeeProp');
  }

  informerCallback(value: CallBackStaff[]) {
    value.forEach((item) => {
      this.pushSearchList(item);
      this.pushSearchRecordList(item);

      if (this.informersIds.indexOf(item.employeeID) <= -1) {
        this.informersIds.push(item.employeeID);
        /*回调后触发change事件*/
        this.informerChange(this.informersIds);
      }
    });
  }

  resetForm() {
    this.ruleForm.joinOrgID.splice(0);
    this.availableRoomList.splice(0);
    this.ruleForm.attendees.splice(0);
    this.ruleForm.informers.splice(0);
    this.ruleForm.endTime = '';

    (this.$refs.ruleForm as any).resetFields();
  }

  emptyAttendee() {
    this.attendeeIds.splice(0);
    this.ruleForm.attendees.splice(0);
    (this.$refs.ruleForm as any).validateField('attendeeProp');
  }

  emptyInformers() {
    this.informersIds.splice(0);
    this.ruleForm.informers.splice(0);
  }

  close() {
    this.resetForm();
    this.$emit('update:isShow', false);
    this.$emit('update:isCopy', false);
  }

  attendeePropVerify(rule: any, val: number[], callback: any) {
    if (this.attendeeIds.length <= 0) callback(new Error('请选择参会人'));
    else callback();
  }

  verifyBeginTime(rule: any, value: string, callback: any) {
    if (!value || !this.ruleForm.endTime) callback(new Error('请选择开始时间和结束时间'));
    else callback();
  }

  verifyJoinOrgID(rule: any, value: any[], callback: any) {
    if (value.length <= 0 && !this.ruleForm.joinSingleOrgID) callback(new Error('请选择参会基地'));
    else callback();
  }

  verifyRules: any = {
    beginTime: {
      required: true,
      trigger: 'change',
      validator: this.verifyBeginTime
    },
    callerId: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: string, callback: any) => {
        if (!value) callback(new Error('请选择主持人'));
        else callback();
      }
    },
    approvalOrgID: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择会议审批'));
        else callback();
      }
    },
    joinOrgID: {
      required: true,
      trigger: 'change',
      validator: this.verifyJoinOrgID
    },
    attendeeProp: {
      required: true,
      trigger: 'change',
      validator: this.attendeePropVerify
    }
  };
}
