export enum StateEnum {
    /**草稿*/
    Draft = 1,
    /*等待审批*/
    Pending = 2,
    /*审批通过*/
    Adopt = 3,
    /*取消*/
    Cancel = 4,
    /*拒绝*/
    Reject = 5,
    /*提前结束*/
    Finish = 6
}

export enum CycleModeEnum {
    /*按天重复*/
    Day = 1,
    /*按周重复*/
    Week = 2,
    /*按月重复*/
    Month = 3,
}

export enum MeetingTypeEnum {
    /*视频会议*/
    Video = 1,
    /*非视频会议*/
    NonVideo = 2,
    /*洽谈室*/
    Negotiation = 3
}

export enum MeetingModeEnum {
    /*单次*/
    Single = 1,
    /*周期*/
    Cycle = 2
}
