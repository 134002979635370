import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/view-meeting/:id",
    name: "ViewMeeting",
    component: () => import("@/views/components/EventPage.vue")
  },
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/error-page/401.vue")
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error-page/404.vue")
  },
  {
    path: "/list",
    name: "List",
    component: () => import("@/views/query-meeting/meeting-list.vue")
  },
  {
    path: "/room",
    name: "Room",
    component: () => import("@/views/meeting-room/room-list.vue")
  },
  {
    path: "/permission",
    name: "Permission",
    component: () => import("@/views/permission-seeting/index.vue")
  },
  {
    path: "/cycle",
    name: "Cycle",
    component: () => import("@/views/query-meeting/cycle-meeting-list.vue")
  },
  {
    path: "*",
    redirect: "/404"
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
});

export default router

//过滤权限
// router.beforeEach((to: Route, from: Route, next: any) => {
//
// })