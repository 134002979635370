import ajax from '@/util/ajax'

/**获取组织机构*/
export const getOrganizationList = () => ajax({
    url: "/api/services/app/BasicData/GetOrganizationList",
    method: "get"
});

/**获取会议类型*/
export const getMeetingType = () => ajax({
    url: "/api/services/app/BasicData/GetMeetingType",
    method: "get"
});

/**获取会议室类型*/
export const getRoomType = () => ajax({
    url: "/api/services/app/BasicData/GetRoomType",
    method: "get"
});

/**获取审批状态*/
export const getMeetingApprovalState = () => ajax({
    url: "/api/services/app/BasicData/GetMeetingApprovalState",
    method: "get"
});

/**获取会议模式*/
export const getMeetingMode = () => ajax({
    url: "/api/services/app/BasicData/GetMeetingMode",
    method: "get"
});

/**获取会议重复模式*/
export const getCycleRepeatMode = () => ajax({
    url: "/api/services/app/BasicData/GetCycleRepeatMode",
    method: "get"
});

/**获取集团所有组织机构（部门）*/
export const getAllOrgansOfTheGroup = () => ajax({
    url: "/api/services/app/BasicData/GetAllOrgansOfTheGroup",
    method: "get"
});

/**根据部门Id获取未离职员工列表*/
export const getEmployeeListByDeptId = (params: any) => ajax({
    url: "/api/services/app/BasicData/GetEmployeeListByDeptId",
    method: "get",
    params
});

/**根据部门Id集合获取未离职员工列表*/
export const getEmployeeListByDeptIds = (params: any) => ajax({
    url: "/api/services/app/BasicData/GetEmployeeListByDeptIds",
    method: "get",
    params
});

/**员工姓名、员工号、部门名称模糊搜索*/
export const getEmployeeListBySearch = (params: any) => ajax({
    url: "/api/services/app/BasicData/GetEmployeeListBySearch",
    method: "get",
    params
});

/**根据工号获取员工信息*/
export const getEmployeeById = (params: any) => ajax({
    url: "/api/services/app/BasicData/GetEmployeeById",
    method: "get",
    params
});

/**查询会议常用人员列表*/
export const getOftenUsedList = (params: any) => ajax({
    url: "/api/services/app/BasicData/GetOftenUsedList",
    method: "get",
    params
});