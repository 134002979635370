




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseDataModule from '@/store/modules/base-data';
import EmployeeInfo from '@/store/entities/employee-info';
import CallBackStaff from '@/store/entities/callback-staff';

@Component
export default class Anchor extends Vue {
  @Prop({ type: Boolean, required: true }) show!: boolean;
  @Prop({ type: Function }) onSelected: any;

  inputValue: string = '';
  activeName: string = 'first';

  defaultProps: any = {
    label: 'name',
    isLeaf: 'leaf'
  };

  get tableData() {
    return BaseDataModule.sameDeptStaffData;
  }

  get oftenList() {
    return BaseDataModule.oftenList;
  }

  async employeeSearch(queryString: string, cb: any) {
    await BaseDataModule.getEmployeeListBySearch({ searchKeyword: queryString })
      .then((res) => {
        let list = res.result;
        cb(list);
      })
      .catch((e) => {
        cb([]);
      });
  }

  handleNodeClick(data: any) {
    if (data.isEmployee) {
      let caller: CallBackStaff = {
        employeeID: data.id,
        employeeName: data.name,
        positionName: data.positionName
      };

      this.close();
      this.onSelected(caller);
    }
  }

  handleSelect(value: any) {
    this.inputValue = value.employeeName;
    this.close();
    this.onSelected(value);
  }

  async loadNode(node: any, resolve: any) {
    if (node.level <= 0) {
      let childlist = BaseDataModule.allOrgDeptList.filter((x) => x.parentId == 99999);
      return resolve(childlist);
    } else if (node.data.isParent) {
      let childlist = BaseDataModule.allOrgDeptList.filter((x) => x.parentId == node.data.id);
      /**查询员工列表*/
      await BaseDataModule.getEmployeeListByDeptId({ deptId: node.data.id }).then((res) => {
        let result: any = res.result.map((x: EmployeeInfo) => ({
          leaf: true,
          isEmployee: true,
          id: x.employeeID,
          name: x.employeeName,
          positionName: x.positionName
        }));

        let joinArray = [...result, ...childlist];
        return resolve(joinArray);
      });
    } else {
      /**查询员工列表*/
      await BaseDataModule.getEmployeeListByDeptId({ deptId: node.data.id }).then((res) => {
        let result: any = res.result.map((x: EmployeeInfo) => ({
          leaf: true,
          isEmployee: true,
          id: x.employeeID,
          name: x.employeeName,
          positionName: x.positionName
        }));
        return resolve(result);
      });
    }
  }

  tableRowClick(row: any, column: any, event: any) {
    let caller = {
      employeeID: row.employeeID,
      employeeName: row.employeeName,
      positionName: row.positionName
    };

    this.close();
    this.onSelected(caller);
  }

  handleClick(tab: any, event: any) {
    // console.log(tab, event);
  }

  close() {
    this.inputValue = '';
    this.$emit('update:show', false);
  }
}
