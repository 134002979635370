import store from '@/store'
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import * as api from '@/api/base-data'
import OrganInfo, { OrganDeptInfo } from '@/store/entities/organ-info'
import KeyValuePair from '@/store/entities/key-value-pair'
import EmployeeInfo from '@/store/entities/employee-info'

@Module({ dynamic: true, store, name: "basedata", namespaced: true })
class BaseDataStore extends VuexModule {
    currentUser: EmployeeInfo = new EmployeeInfo();
    orgList: Array<OrganInfo> = new Array<OrganInfo>();
    allOrgDeptList: Array<OrganDeptInfo> = new Array<OrganDeptInfo>();
    sameDeptStaffData: Array<EmployeeInfo> = new Array<EmployeeInfo>();
    meetingTypeList: Array<KeyValuePair> = new Array<KeyValuePair>();
    cycleRepeatList: Array<KeyValuePair> = new Array<KeyValuePair>();
    meetingStatusList: Array<KeyValuePair> = new Array<KeyValuePair>();
    meetingModeList: Array<KeyValuePair> = new Array<KeyValuePair>();
    roomTypeList: Array<KeyValuePair> = new Array<KeyValuePair>();
    oftenList: Array<any> = new Array<any>();

    @Mutation
    SET_ROOMTYPELIST(data: any) {
        this.roomTypeList = data.result;
    }

    @Mutation
    SET_CURRENTUSER(data: any) {
        this.currentUser = data.result;
    }

    @Mutation
    SET_ORGLIST(data: any) {
        this.orgList = data.result;
    }

    @Mutation
    SET_ORGDEPTLIST(data: any) {
        this.allOrgDeptList = data.result;
    }

    @Mutation
    SET_MEETINGTYPELIST(data: any) {
        this.meetingTypeList = data.result;
    }

    @Mutation
    SET_MEETINGAPPROVALSTATE(data: any) {
        this.meetingStatusList = data.result;
    }

    @Mutation
    SET_MEETINGMODELIST(data: any) {
        this.meetingModeList = data.result;
    }

    @Mutation
    SET_CYCLEREPEATLIST(data: any) {
        this.cycleRepeatList = data.result;
    }

    @Mutation
    SET_SAMEDEPTSTAFFDATA(data: any) {
        this.sameDeptStaffData = data.result;
    }

    @Mutation
    SET_OFTENLISTDATA(data: any) {
        this.oftenList = data.result;
    }

    @Action({ commit: "SET_ORGLIST" })
    public async getOrganizationList() {
        let result = await api.getOrganizationList();
        return result.data;
    }

    @Action({ commit: "SET_ORGDEPTLIST" })
    public async getAllOrgansOfTheGroup() {
        let result = await api.getAllOrgansOfTheGroup();
        return result.data;
    }

    @Action({ commit: "SET_MEETINGTYPELIST" })
    public async getMeetingType() {
        let result = await api.getMeetingType();
        return result.data;
    }

    @Action({ commit: "SET_ROOMTYPELIST" })
    public async getRoomType() {
        let result = await api.getRoomType();
        return result.data;
    }

    @Action({ commit: "SET_MEETINGAPPROVALSTATE" })
    public async getMeetingApprovalState() {
        let result = await api.getMeetingApprovalState();
        return result.data;
    }

    @Action({ commit: "SET_MEETINGMODELIST" })
    public async getMeetingMode() {
        let result = await api.getMeetingMode();
        return result.data;
    }

    @Action({ commit: "SET_CYCLEREPEATLIST" })
    public async getCycleRepeatMode() {
        let result = await api.getCycleRepeatMode();
        return result.data;
    }

    /**获取同部门员工数据*/
    @Action({ commit: "SET_SAMEDEPTSTAFFDATA" })
    public async getSameDeptStaffData(params: any) {
        let result = await api.getEmployeeListByDeptId(params);
        return result.data;
    }

    @Action
    public async getEmployeeListByDeptId(params: any) {
        let result = await api.getEmployeeListByDeptId(params);
        return result.data;
    }

    @Action
    public async getEmployeeListByDeptIds(params: any) {
        let result = await api.getEmployeeListByDeptIds(params);
        return result.data;
    }

    @Action
    public async getEmployeeListBySearch(params: any) {
        let result = await api.getEmployeeListBySearch(params);
        return result.data;
    }

    @Action({ commit: "SET_CURRENTUSER" })
    public async getEmployeeById(params: any) {
        let result = await api.getEmployeeById(params);
        return result.data;
    }

    @Action({ commit: "SET_OFTENLISTDATA" })
    public async getOftenUsedList(params: any) {
        let result = await api.getOftenUsedList(params);
        return result.data;
    }
}

export default getModule(BaseDataStore);