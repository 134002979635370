



































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseDataModule from '@/store/modules/base-data';
import MeetingModule from '@/store/modules/meeting';
import MeetingInfo from '@/store/entities/meeting-info';
import Anchor from '@/views/components/Anchor.vue';
import Collective from '@/views/components/Collective.vue';
import OrganInfo from '@/store/entities/organ-info';
import { StateEnum, CycleModeEnum, MeetingTypeEnum } from '@/store/entities/state-enum';
import { datetimeFormat } from '@/util/date-util';
import CallBackStaff from '@/store/entities/callback-staff';

@Component({
  components: {
    Anchor,
    Collective
  }
})
export default class CycleReserve extends Vue {
  @Prop({ default: false }) isShow!: boolean;

  searchLoading: boolean = false;
  attendeeIds: number[] = [];
  informersIds: number[] = [];
  searchList: CallBackStaff[] = [];
  searchRecordList: CallBackStaff[] = [];

  ruleForm: MeetingInfo = new MeetingInfo();
  state: any = StateEnum;

  showAlert: boolean = false;
  showAnchor: boolean = false;
  showCollective: boolean = false;
  showInformer: boolean = false;
  arLoading: boolean = false;
  submitLoading: boolean = false;
  saveLoading: boolean = false;

  get currentUser() {
    return BaseDataModule.currentUser;
  }

  get allRoomList() {
    return MeetingModule.allRoomList;
  }

  get orgList() {
    return BaseDataModule.orgList;
  }

  get meetingTypeList() {
    return BaseDataModule.meetingTypeList;
  }

  get cycleRepeatList() {
    let validMode = [CycleModeEnum.Week, CycleModeEnum.Month];
    return BaseDataModule.cycleRepeatList.filter((x) => validMode.indexOf(Number(x.key)) > -1);
  }

  @Watch('ruleForm', { deep: true })
  fetchValue(newVal: MeetingInfo, oldVal: MeetingInfo) {
    if (newVal.name) {
      if (!window.localStorage) {
        this.$message.error('浏览器不支持localStorage');
      } else {
        let _d = JSON.stringify(this.ruleForm);
        localStorage.setItem('cycleModel', _d);
      }
    }
  }

  async mounted() {
    await MeetingModule.getAllRoomList({ type: MeetingTypeEnum.Video, name: '' });
  }

  async save(status: StateEnum) {
    (this.$refs.ruleFormRef as any).validate((valid: boolean) => {
      if (valid) {
        /*判断会议开始时间不能大于会议结束时间*/
        if (this.ruleForm.beginTime > this.ruleForm.endTime) {
          this.$message.warning('会议开始时间不能大于会议结束时间');
          return;
        }

        /*判断视频会议必须选择2个以上会议室*/
        if (this.ruleForm.meetingType == MeetingTypeEnum.Video && this.ruleForm.ckIds.length < 2) {
          this.$message.error('视频会议必须选择2个或以上会议室');
          return;
        }

        /*判断周期会议只能预定3个月内的会议*/
        let rStart = new Date(this.ruleForm.repeatStartDate);
        let rEnd = new Date(this.ruleForm.repeatEndDate);
        let diffMonth = (rEnd.valueOf() - rStart.valueOf()) / 1000 / 60 / 60 / 24 / 30;
        if (diffMonth >= 3.1 && this.ruleForm.cycleRepeatMode == 2) {
          this.$message.warning('只能预定3个月内（包含）的周期会议。');
          return;
        }
        if (diffMonth >= 6.1 && this.ruleForm.cycleRepeatMode == 3) {
          this.$message.warning('只能预定6个月内（包含）的周期会议。');
          return;
        }

        if (this.ruleForm.attendees.length <= 1 && status != StateEnum.Draft) {
          this.$confirm('建议您完整选择本次会议的参会人，以便排查您召集的参会人是否存在日程冲突...', '提示', {
            confirmButtonText: '选择无误,继续提交',
            cancelButtonText: '我再想想',
            type: 'warning'
          })
            .then(() => {
              this.saveSpecificOperation(status);
            })
            .catch(() => {
              return;
            });
        } else {
          this.saveSpecificOperation(status);
        }
      }
    });
  }

  async saveSpecificOperation(status: StateEnum) {
    let _message = '';
    if (status == StateEnum.Draft) {
      this.saveLoading = true;
      _message = '确认保存草稿？';
    } else {
      this.submitLoading = true;
      _message = '确认提交审批？';
    }

    this.ruleForm.status = status;
    this.ruleForm.subscriberId = Number(this.currentUser.employeeID);
    this.ruleForm.subscriberName = this.currentUser.employeeName;
    this.ruleForm.approvalOrgName = (this.orgList.find((x) => x.id === this.ruleForm.approvalOrgID) as OrganInfo)?.shortName;
    this.ruleForm.relationRooms = this.allRoomList
      .filter((x) => this.ruleForm.ckIds.indexOf(x.id) > -1)
      .map((t) => ({
        key: t.id,
        value: t.name
      }));

    this.$confirm(_message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(async () => {
        this.ruleForm.meetingMode = 2;
        await MeetingModule.atTheSameTime(this.ruleForm).then(async (res) => {
          if (res) {
            let remindMsg = `${res}在此时间段（${this.ruleForm.beginTime}~${this.ruleForm.endTime}）需要参加其他会议（时间冲突）,是否继续提交？`;
            this.$confirm(remindMsg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(async () => {
                await this.createMeeting();
              })
              .catch(() => {
                this.saveLoading = false;
                this.submitLoading = false;
              });
          } else {
            await this.createMeeting();
          }
        });
      })
      .catch(() => {
        this.saveLoading = false;
        this.submitLoading = false;
      });
  }

  async createMeeting() {
    await MeetingModule.createCycleMeeting(this.ruleForm)
      .then((res) => {
        this.$message.success('操作成功');
        this.$emit('refreshData');
        this.clearLS();
        this.close();
      })
      .finally(() => {
        this.saveLoading = false;
        this.submitLoading = false;
      });
  }

  async meetingTypeChange(val: number) {
    this.arLoading = true;

    let _orgId = 0;
    if (val != 1) _orgId = this.ruleForm.approvalOrgID;

    await MeetingModule.getAllRoomList({ type: val, name: '', orgId: _orgId }).finally(() => {
      setTimeout(() => {
        this.arLoading = false;
      }, 600);
    });
  }

  async approvalChange(val: number) {
    this.arLoading = true;

    let _orgId = 0;
    if (this.ruleForm.meetingType != 1) _orgId = val;

    await MeetingModule.getAllRoomList({ type: this.ruleForm.meetingType, name: '', orgId: _orgId }).finally(() => {
      setTimeout(() => {
        this.arLoading = false;
      }, 600);
    });
  }

  async remoteMethod(q: string) {
    this.searchLoading = true;
    await BaseDataModule.getEmployeeListBySearch({ searchKeyword: q })
      .then((res) => {
        this.searchList = res.result.map((item: CallBackStaff) => {
          let o: CallBackStaff = {
            employeeID: item.employeeID,
            employeeName: item.employeeName,
            positionName: item.positionName
          };
          this.pushSearchRecordList(o);
          return o;
        });
      })
      .finally(() => {
        this.searchLoading = false;
      });
  }

  async open() {
    let _d = localStorage.getItem('cycleModel');
    if (_d) {
      this.showAlert = true;
      this.ruleForm = JSON.parse(_d);
    } else {
      this.showAlert = false;
    }

    this.loadSelectedCache();
  }

  pushSearchList(item: CallBackStaff) {
    let _index = this.searchList.findIndex((x) => x.employeeID == item.employeeID);
    if (_index <= -1) {
      this.searchList.push(item);
    }
  }

  pushSearchRecordList(item: CallBackStaff) {
    let _index = this.searchRecordList.findIndex((x) => x.employeeID == item.employeeID);
    if (_index <= -1) {
      this.searchRecordList.push(item);
    }
  }

  loadSelectedCache() {
    /*读取主持人缓存*/
    if (this.ruleForm.callerId) {
      let o = {
        employeeID: this.ruleForm.callerId,
        employeeName: this.ruleForm.callerName,
        positionName: this.ruleForm.positionName
      };
      this.pushSearchList(o);
      this.pushSearchRecordList(o);
    }

    /*读取参会人缓存*/
    if (this.ruleForm.attendees) {
      this.attendeeIds = this.ruleForm.attendees.map((t) => {
        return t.key;
      });

      this.ruleForm.attendees.forEach((x) => {
        let o = {
          employeeID: x.key,
          employeeName: x.value,
          positionName: x.positionName
        };
        this.pushSearchList(o);
        this.pushSearchRecordList(o);
      });
    }
  }

  clearLS() {
    localStorage.removeItem('cycleModel');
    this.showAlert = false;
  }

  roomCkChange(val: any) {
    if (val.length <= 0) return;

    let lastId = val[val.length - 1];
    let orgId = this.allRoomList.filter((x) => x.id == lastId)[0].organId;
    let ids = this.allRoomList.filter((t) => t.organId == orgId && t.id != lastId).map((x) => x.id);

    ids.forEach((item) => {
      let _index = this.ruleForm.ckIds.indexOf(item);
      if (_index > -1) {
        this.ruleForm.ckIds.splice(_index, 1);
      }
    });
  }

  anchorChange(val: any) {
    if (!val) return;

    let item = this.searchRecordList.find((x) => x.employeeID == val) as CallBackStaff;
    this.ruleForm.callerName = item.employeeName;
  }

  attendeeChange(val: number[]) {
    let selectedAll = this.searchRecordList.filter((x) => val.indexOf(x.employeeID) > -1);
    this.ruleForm.attendees = selectedAll.map((x) => ({
      key: x.employeeID,
      value: x.employeeName,
      positionName: x.positionName
    }));
  }

  informerChange(val: number[]) {
    let selectedAll = this.searchRecordList.filter((x) => val.indexOf(x.employeeID) > -1);
    this.ruleForm.informers = selectedAll.map((x) => ({
      key: x.employeeID,
      value: x.employeeName,
      positionName: x.positionName
    }));
  }

  anchorCallback(value: any) {
    this.pushSearchList(value);
    this.pushSearchRecordList(value);

    this.ruleForm.callerId = value.employeeID;
    this.ruleForm.callerName = value.employeeName;
    this.ruleForm.positionName = value.positionName;
    (this.$refs.ruleFormRef as any).validateField('callerId');
  }

  attendeeCallback(value: any) {
    value.forEach((item) => {
      this.pushSearchList(item);
      this.pushSearchRecordList(item);

      if (this.attendeeIds.indexOf(item.employeeID) <= -1) {
        this.attendeeIds.push(item.employeeID);
        /*回调后触发change事件*/
        this.attendeeChange(this.attendeeIds);
      }
    });

    (this.$refs.ruleFormRef as any).validateField('attendeeProp');
  }

  informerCallback(value: any) {
    value.forEach((item) => {
      this.pushSearchList(item);
      this.pushSearchRecordList(item);

      if (this.informersIds.indexOf(item.employeeID) <= -1) {
        this.informersIds.push(item.employeeID);
        /*回调后触发change事件*/
        this.informerChange(this.informersIds);
      }
    });
  }

  emptyAttendee() {
    this.attendeeIds.splice(0);
    this.ruleForm.attendees.splice(0);
  }

  emptyInformers() {
    this.informersIds.splice(0);
    this.ruleForm.informers.splice(0);
  }

  resetForm() {
    this.ruleForm.attendees.splice(0);
    this.ruleForm.informers.splice(0);
    this.ruleForm.endTime = '';

    (this.$refs.ruleFormRef as any).resetFields();
  }

  close() {
    this.resetForm();
    this.$emit('update:isShow', false);
  }

  disabledStart(dt: Date) {
    let now = datetimeFormat(new Date()) + ' 00:00:00';
    let dNow = new Date(now);

    if (this.ruleForm.repeatEndDate) return !(dt >= dNow && dt <= new Date(this.ruleForm.repeatEndDate + ' 00:00:00'));
    else return dt < dNow;
  }

  disabledEnd(dt: Date) {
    let now = datetimeFormat(new Date()) + ' 00:00:00';
    let dNow = new Date(now);

    if (this.ruleForm.repeatStartDate) return dt < new Date(this.ruleForm.repeatStartDate + ' 00:00:00');
    else return dt < dNow;
  }

  attendeePropVerify(rule: any, value: string, callback: any) {
    if (this.ruleForm.attendees.length <= 0) callback(new Error('请选择参会人'));
    else callback();
  }

  weeksVerify(rule: any, value: any[], callback: any) {
    if (value.length <= 0) {
      callback(new Error('请选择重复间隔'));
    } else {
      callback();
    }
  }

  repeatIntervalDaysVerify(rule: any, value: string, callback: any) {
    if (Number(value) < 1 || Number(value) > 31) {
      callback(new Error('请填写1~31之间的数字'));
    } else {
      callback();
    }
  }

  verifyRepeatStartDate(rule: any, value: string, callback: any) {
    if (!value || !this.ruleForm.repeatEndDate) callback(new Error('请选择开始日期和结束日期'));
    else callback();
  }

  verifyBeginTime(rule: any, value: string, callback: any) {
    if (!value || !this.ruleForm.endTime) callback(new Error('请选择开始时间和结束时间'));
    else callback();
  }

  verifyRules: any = {
    beginTime: {
      required: true,
      trigger: 'change',
      validator: this.verifyBeginTime
    },
    repeatStartDate: {
      required: true,
      trigger: 'change',
      validator: this.verifyRepeatStartDate
    },
    callerId: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择主持人'));
        else callback();
      }
    },
    approvalOrgID: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择会议审批'));
        else callback();
      }
    },
    cycleRepeatMode: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择会议重复模式'));
        else callback();
      }
    },
    weeks: {
      required: true,
      type: 'array',
      trigger: 'change',
      validator: this.weeksVerify
    },
    repeatIntervalDays: {
      required: true,
      trigger: 'change',
      validator: this.repeatIntervalDaysVerify
    },
    attendeeProp: {
      trigger: 'change',
      required: true,
      validator: this.attendeePropVerify
    }
  };
}
