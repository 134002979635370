














































































































































































































import { Component, Vue } from 'vue-property-decorator';
import FullCalendar, { CalendarOptions, EventApi, DateSelectArg, EventClickArg, EventHoveringArg, EventContentArg, Calendar } from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { ResourceLabelContentArg } from '@fullcalendar/resource-common'
import { datetimeFormat } from '@/util/date-util'
import CalendarX from 'vue-calendar-component'
import Reserve from '@/views/components/Reserve.vue'
import CycleReserve from '@/views/components/CycleReserve.vue'
import EventView from '@/views/components/EventView.vue'
import BaseDataModule from '@/store/modules/base-data'
import MeetingModule from '@/store/modules/meeting'
import { ResourceDto } from '@/store/entities/key-value-pair'
import { StateEnum } from '@/store/entities/state-enum';

@Component({
  components: { FullCalendar, Reserve, EventView, CycleReserve, CalendarX }
})
export default class Home extends Vue {
  showReserve: boolean = false;
  showCycleReserve: boolean = false;
  showEventView: boolean = false;
  initCopy: boolean = false;

  id: number = 0;
  orgId: number = 0;
  roomList: Array<ResourceDto> = new Array<ResourceDto>();
  currentEvents: EventApi[] = [];
  startDT: any = null;
  endDT: any = null;
  textTop: any = ['日', '一', '二', '三', '四', '五', '六'];

  calendarOptions: CalendarOptions = {
    customButtons: {
      "AddCycleBtn": {
        text: '添加周期会议',
        click: this.AddCycleMeeting
      }
    },
    plugins: [interactionPlugin, resourceTimelinePlugin],
    headerToolbar: { left: 'AddCycleBtn', center: "title", right: 'today prev,next' },
    buttonText: { today: "今天" },
    resourceOrder: "sort",
    resources: this.roomList,
    resourceAreaColumns: [{ field: "title", headerContent: "会议室" }],
    slotLabelFormat: {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    },
    height: "auto",
    resourceAreaWidth: "150px",
    initialView: 'resourceTimeline',
    locale: "zh-cn",
    schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
    slotMinTime: "08:00",
    slotMaxTime: "20:00",
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    },
    eventDisplay: 'block',
    displayEventTime: true,
    displayEventEnd: true,
    events: this.loadEvents,
    eventContent: this.renderEvents,
    eventMouseEnter: this.setEventTitle,
    resourceLabelContent: this.renderResource,
    select: this.handleDateSelect,
    eventClick: this.handleEventClick,
    eventsSet: this.handleEvents,
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  }

  get calendarApi(): Calendar {
    return (this.$refs.fullCalendar as any).getApi();
  }

  get orgList() {
    return BaseDataModule.orgList;
  }

  copyMeeting() {
    this.initCopy = true;
    this.showReserve = true;
  }

  choseDay(data: any) {
    let _date = new Date(data)
    this.calendarApi.gotoDate(_date);
  }

  async mounted() {
    await BaseDataModule.getOrganizationList();
    await this.getRoomList(1);

    let userId = this.$route.query.id;
    if (!userId) {
      this.$alert('未获取到当前登录用户信息', '提示', {
        type: "warning",
        confirmButtonText: '确定',
        callback: action => {
          this.$router.replace({ path: '/401' });
        }
      });
    } else {
      await BaseDataModule.getEmployeeById({ employeeId: userId });
    }
  }

  async getRoomList(id: number) {
    this.orgId = id;
    this.roomList.splice(0);
    await MeetingModule.getAllRoomByOrgId({ orgId: id }).then((res: Array<any>) => {
      res.forEach((v, i) => {
        this.roomList.push({
          id: v.id,
          title: v.name,
          isSupportVideo: v.isSupportVideo,
          sort: v.sort,
          seatNum: v.seatNum
        });
      });
    });

    this.calendarApi.refetchEvents();
  }

  AddCycleMeeting() {
    this.showCycleReserve = true;
  }

  renderResource(arg: ResourceLabelContentArg) {
    let _html = `<span>${arg.resource.title}</span>`;
    if (arg.resource._resource.extendedProps.isSupportVideo) {
      _html += `<i class="f-icon-exhibithion" style="color:orange;margin-left:2px;"></i>`;
    }

    _html += `<div class="el-badge item" style="margin-left:5px;">
                <i class="f-icon-zuoyi3" style="color: orange;"></i>
                <sup class="el-badge__content el-badge__content--primary is-fixed">${arg.resource._resource.extendedProps.seatNum}</sup>
              </div>`;

    _html = `<div style="margin-top: 3px;">${_html}</div>`

    return { html: _html };
  }

  renderEvents(arg: EventContentArg) {
    // let italicEl = document.createElement('i')
    // if (arg.event.extendedProps.isUrgent) {
    //   italicEl.innerHTML = 'urgent event'
    // } else {
    //   italicEl.innerHTML = 'normal event'
    // }

    // let arrayOfDomNodes = [italicEl]
    // return { domNodes: arrayOfDomNodes }
    // let _html = "<div>";
    // _html += `<p>${arg.timeText}</p>`;
    // _html += `<p>${arg.event.title}</p>`;
    // _html += "</div>";
    // return { html: _html };
  }

  setEventTitle(arg: EventHoveringArg) {
    if (!arg.el.getAttribute("title")) {
      let _title = `${datetimeFormat(arg.event.start as Date, "hh:mm")}-${datetimeFormat(arg.event.end as Date, "hh:mm")} ${arg.event.title}`;
      arg.el.setAttribute("title", _title);
    }
  }

  loadEvents(fetchInfo: any, successCallback: any, failureCallback: any) {
    MeetingModule.getSameDayEvents({
      date: datetimeFormat(fetchInfo.start, "yyyy-MM-dd"),
      orgId: this.orgId
    }).then((res: any) => {
      res.forEach((v: any, i: number) => {
        if (v.status == StateEnum.Adopt || v.status == StateEnum.Finish) {
          v.color = "#97D542";
          v.textColor = "black";
          v.className = "meeting-adopt";
        } else {
          v.textColor = "black";
          v.className = "meeting-unadopt";
        }
      });
      successCallback(res);
    });
  }

  calendarFomatter(date: Date) {
    return date.getDate();
  }

  handleWeekendsToggle() {
    this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.startDT = selectInfo.start;
    this.endDT = selectInfo.end;
    this.showReserve = true;
    this.initCopy = false;
    // console.log(datetimeFormat(this.calendarApi.getDate()));
    // let title = prompt('Please enter a new title for your event')
    // let calendarApi = selectInfo.view.calendar

    // calendarApi.unselect(); // clear date selection

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   })
    // }
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.showEventView = true;
    this.id = clickInfo.event.extendedProps.subId;

    // this.id = clickInfo.event.id;
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
}
