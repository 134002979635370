
















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseDataModule from '@/store/modules/base-data';
import EmployeeInfo from '@/store/entities/employee-info';
import CallBackStaff from '@/store/entities/callback-staff';

@Component
export default class Collective extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Boolean, required: true }) show!: boolean;
  @Prop({ type: Function }) onSelected: any;

  activeName: string = 'first';
  deptIds: any = [];
  value: string = '';
  existValue: string = '';
  nodeClickCount = 0;

  loading: boolean = false;
  searchLoading: boolean = false;
  showSearch: boolean = false;
  showExistBox: boolean = false;

  searchData: Array<any> = [];
  tableData: Array<CallBackStaff> = [];
  newTableData: Array<CallBackStaff> = [];

  defaultProps: any = {
    label: 'name',
    isLeaf: 'leaf'
  };

  get sameDeptData() {
    return BaseDataModule.sameDeptStaffData;
  }

  get oftenList() {
    return BaseDataModule.oftenList;
  }

  async loadSearchData(queryString: string) {
    await BaseDataModule.getEmployeeListBySearch({ searchKeyword: queryString }).then((res) => {
      this.searchData = res.result;
      setTimeout(() => {
        this.searchLoading = false;
      }, 1000);
    });
  }

  async loadNode(node: any, resolve: any) {
    if (node.level <= 0) {
      let oneLevel = BaseDataModule.allOrgDeptList.filter((x) => x.parentId == 99999);
      oneLevel.forEach((item, index) => {
        item.level = node.level + 1;
      });
      return resolve(oneLevel);
    } else if (node.data.isParent) {
      let childlist = BaseDataModule.allOrgDeptList.filter((x) => x.parentId == node.data.id);
      childlist.forEach((item, index) => {
        item.level = node.level + 1;
      });

      /**查询员工列表*/
      await BaseDataModule.getEmployeeListByDeptId({ deptId: node.data.id }).then((res) => {
        let result: any = res.result.map((x: EmployeeInfo) => ({
          leaf: true,
          isEmployee: true,
          id: x.employeeID,
          name: x.employeeName,
          positionName: x.positionName
        }));

        let joinArray = [...result, ...childlist];
        return resolve(joinArray);
      });
    } else {
      /**查询员工列表*/
      await BaseDataModule.getEmployeeListByDeptId({ deptId: node.data.id }).then((res) => {
        let result: any = res.result.map((x: EmployeeInfo) => ({
          leaf: true,
          isEmployee: true,
          id: x.employeeID,
          name: x.employeeName,
          positionName: x.positionName
        }));

        return resolve(result);
      });
    }
  }

  nodeClick(node: any) {
    this.nodeClickCount++;
    if (this.nodeClickCount >= 2) return;

    setTimeout(() => {
      if (this.nodeClickCount <= 1) {
        this.nodeClickCount = 0;
      } else if (this.nodeClickCount > 1) {
        this.nodeClickCount = 0;
        if (node.isEmployee) {
          /**选择单个员工*/
          let _index = this.tableData.findIndex((x) => x.employeeID == node.id);
          if (_index <= -1) {
            this.tableData.push({
              employeeID: node.id,
              employeeName: node.name,
              positionName: node.positionName
            });
          }
        }
      }
    }, 200);
  }

  clearAll() {
    this.tableData.splice(0);
    this.newTableData.splice(0);
    (this.$refs.sameDeptTabTable as any).clearSelection();
    (this.$refs.oftenTabTable as any).clearSelection();
  }

  handleSure() {
    let result: any = JSON.stringify(this.tableData);

    this.close();
    this.onSelected(JSON.parse(result));
  }

  blurSearch() {
    if (!this.value) this.showSearch = false;
  }

  last: number = 0;
  searchStaff(event: any) {
    this.last = event.timeStamp;
    setTimeout(async () => {
      if (this.last == event.timeStamp && !!this.value) {
        this.showSearch = true;
        this.searchLoading = true;
        await this.loadSearchData(this.value);
      }
    }, 1000);
  }

  existLast: number = 0;
  searchExistStaff(event: any) {
    this.existLast = event.timeStamp;
    this.loading = true;

    setTimeout(async () => {
      if (this.existLast == event.timeStamp) {
        if (!this.existValue) {
          this.loading = false;
          this.showExistBox = false;
        } else {
          this.showExistBox = true;
          this.newTableData = this.tableData.filter((x) => x.employeeName.indexOf(this.existValue) > -1);
          this.loading = false;
        }
      }
    }, 600);
  }

  transfer() {
    if (this.showSearch) {
      /**选择搜索出来的员工*/
      let staffList = (this.$refs.searchTable as any).selection;
      if (staffList.length <= 0) {
        this.$message.warning('未选择参会人');
        return;
      }

      staffList.forEach((item: CallBackStaff) => {
        let _index = this.tableData.findIndex((x) => x.employeeID == item.employeeID);
        if (_index <= -1) {
          // let attendee = {
          //   id: item.employeeID,
          //   name: item.employeeName,
          //   positionName: item.positionName
          // };
          this.tableData.push(item);
        }
      });
    } else {
      /**选择非搜索出来的员工*/
      this.transferTree();
    }
  }

  transferTree() {
    let result = (this.$refs.reftree as any).getCurrentNode();
    if (!result) {
      this.$message.warning('未选择参会人');
      return;
    }

    if (result.isEmployee) {
      /**选择单个员工*/
      let _index = this.tableData.findIndex((x) => x.employeeID == result.id);
      if (_index <= -1) {
        let attendee: CallBackStaff = {
          employeeID: result.id,
          employeeName: result.name,
          positionName: result.positionName
        };
        this.tableData.push(attendee);
      }
    } else {
      /**选择机构或部门*/
      this.deptIds.splice(0);
      this.recursionNode(result);
      let _deptIds = this.deptIds.join(',');

      this.loading = true;
      BaseDataModule.getEmployeeListByDeptIds({ deptIds: _deptIds }).then((res) => {
        res.result.forEach((item: any) => {
          let _index = this.tableData.findIndex((x) => x.employeeID == item.employeeID);
          if (_index <= -1) {
            let attendee: CallBackStaff = {
              employeeID: item.employeeID,
              employeeName: item.employeeName,
              positionName: item.positionName
            };
            this.tableData.push(attendee);
          }
        });

        this.loading = false;
      });
    }
  }

  recursionNode(node: any) {
    if (node.level > 1) {
      this.deptIds.push(node.id);
    }

    BaseDataModule.allOrgDeptList
      .filter((x) => x.parentId == node.id)
      .forEach((item) => {
        this.deptIds.push(item.id);
        this.recursionNode(item);
      });
  }

  /**删除单个已选择人*/
  deleteRow(row: CallBackStaff) {
    let _index = this.tableData.findIndex((x) => x.employeeID == row.employeeID);
    if (_index > -1) this.tableData.splice(_index, 1);

    let _index2 = this.newTableData.findIndex((x) => x.employeeID == row.employeeID);
    if (_index2 > -1) this.newTableData.splice(_index2, 1);

    /*清除选择*/
    let ck = this.sameDeptData.find((f) => f.employeeID == row.employeeID);
    if (ck) (this.$refs.sameDeptTabTable as any).toggleRowSelection(ck, false);

    let ck1 = this.oftenList.find((f) => f.employeeID == row.employeeID);
    if (ck1) (this.$refs.oftenTabTable as any).toggleRowSelection(ck1, false);
  }

  selectChange(selection: any, flag: number) {
    let handleData: any[] = [];
    if (flag == 1) {
      handleData = this.oftenList;
    } else {
      handleData = this.sameDeptData;
    }

    if (selection.length <= 0) {
      /*全不选则清除对应的同部门员工*/
      handleData.forEach((v) => {
        this.deleteRow({ employeeID: v.employeeID } as CallBackStaff);
      });
    } else {
      /*已选择同部门员工加入到已选列表*/
      selection.forEach((v) => {
        let _index = this.tableData.findIndex((x) => x.employeeID == v.employeeID);
        if (_index <= -1) {
          this.tableData.push({
            employeeID: v.employeeID,
            employeeName: v.employeeName,
            positionName: v.positionName
          });
        }
      });
      /*未选员工清除对应的部门员工*/
      let choiceIds = selection.map((m) => m.employeeID);
      let noChoiceIds = handleData.filter((ft) => choiceIds.indexOf(ft.employeeID) <= -1).map((s) => s.employeeID);
      noChoiceIds.forEach((v) => {
        this.deleteRow({ employeeID: v } as CallBackStaff);
      });
    }
  }

  selectAllClick(val: any[]) {
    if (val.length <= 0) {
      this.tableData.splice(0);
    } else {
      this.tableData = val.map((x) => ({
        employeeID: x.employeeID,
        employeeName: x.employeeName,
        positionName: x.positionName
      }));
    }
  }

  rowClick(row: any) {
    (this.$refs.sameDeptTabTable as any).toggleRowSelection(row);
  }

  close() {
    this.tableData.splice(0);
    this.newTableData.splice(0);
    (this.$refs.sameDeptTabTable as any).clearSelection();
    (this.$refs.oftenTabTable as any).clearSelection();
    this.$emit('update:show', false);
  }
}
