import ajax from '@/util/ajax'

export const getAll = (params: any) => ajax({
    url: "/api/services/app/Meeting/GetAll",
    method: "get",
    params
});

export const getAllRoomByPage = (params: any) => ajax({
    url: "/api/services/app/Meeting/GetAllRoomListByPage",
    method: "get",
    params
});

export const getAllRoomByOrgId = (params: any) => ajax({
    url: "/api/services/app/Meeting/GetAllRoomByOrgId",
    method: "get",
    params
});

/**获取可用会议室*/
export const getAvailableRoomList = (params: any) => ajax({
    url: "/api/services/app/Meeting/GetAvailableRoomList",
    method: "get",
    params
});

/**查询全部会议室*/
export const getAllRoomList = (params: any) => ajax({
    url: "/api/services/app/Meeting/GetAllRoomList",
    method: "get",
    params
});

/**创建单次会议申请*/
export const create = (data: any) => ajax({
    url: "/api/services/app/Meeting/Create",
    method: "post",
    data
});

/**创建周期会议申请*/
export const createCycleMeeting = (data: any) => ajax({
    url: "/api/services/app/Meeting/CreateCycleMeeting",
    method: "post",
    data
});

/**查询当天已预订会议室*/
export const getSameDayEvents = (params: any) => ajax({
    url: "/api/services/app/Meeting/GetSameDayEvents",
    method: "get",
    params
});

/**查看会议*/
export const get = (params: any) => ajax({
    url: "/api/services/app/Meeting/Get",
    method: "get",
    params
});

/**查看会议室*/
export const getRoomById = (params: any) => ajax({
    url: "/api/services/app/Meeting/GetRoomById",
    method: "get",
    params
});

/**取消单次会议*/
export const cancelMeeting = (params: any) => ajax({
    url: "/api/services/app/Meeting/CancelMeeting",
    method: "post",
    params
});

/**取消单次会议*/
export const submitDraft = (params: any) => ajax({
    url: "/api/services/app/Meeting/SubmitDraft",
    method: "post",
    params
});

/**编辑会议室*/
export const editMeetingRoom = (data: any) => ajax({
    url: "/api/services/app/Meeting/EditMeetingRoom",
    method: "post",
    data
});

/**启用/禁用会议室*/
export const toggleRoomActive = (params: any) => ajax({
    url: "/api/services/app/Meeting/ToggleRoomActive",
    method: "get",
    params
});

/**判断主持人/参会人是否在同一时间参加多个会议*/
export const atTheSameTime = (data: any) => ajax({
    url: "/api/services/app/Meeting/AtTheSameTime",
    method: "post",
    data
});

/**修改会议*/
export const edit = (data: any) => ajax({
    url: "/api/services/app/Meeting/Edit",
    method: "post",
    data
});

/**提前结束会议*/
export const finishMeeting = (params: any) => ajax({
    url: "/api/services/app/Meeting/FinishMeeting",
    method: "post",
    params
});

/**查询子会议*/
export const getSubMeetingById = (params: any) => ajax({
    url: "/api/services/app/Meeting/GetSubMeetingById",
    method: "get",
    params
});

/**取消所有子会议*/
export const cancelAllSubMeeting = (params: any) => ajax({
    url: "/api/services/app/Meeting/CancelAllSubMeeting",
    method: "post",
    params
});

/**调整参会人员*/
export const adjustParticipants = (data: any) => ajax({
    url: "/api/services/app/Meeting/AdjustParticipants",
    method: "post",
    data
});