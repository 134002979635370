














import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CustomDatePicker extends Vue {
  @Prop({ default: [] }) value;
  @Prop({ default: "开始日期", type: String }) startPlaceholder;
  @Prop({ default: "结束日期", type: String }) endPlaceholder;

  dt: any = "";

  shortcutHeader: any[] = [
    { title: "未来一周", days: 7 },
    { title: "未来一个月", days: 30 },
    { title: "未来三个月", days: 90 }
  ];

  get getShortcutsData() {
    let list: any[] = [];
    for (let item of this.shortcutHeader) {
      let s: any = {
        text: item.title,
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(start.getTime() + 1000 * 3600 * 24 * item.days);
          picker.$emit('pick', [start, end]);
        }
      };

      list.push(s);
    }

    return list;
  }

  pickerOptions: any = {
    shortcuts: this.getShortcutsData
  };

  handleChange(val: string[]) {
    this.$emit("input", this.dt);
  }
}
