import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/util/filters'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/icon/iconfont.css'
import axios from "axios";
import '@/components/global';

Vue.config.productionTip = false
Vue.use(ElementUI, { size: "small" });

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// axios.get(process.env.VUE_APP_APIURL+"Account/GetCurrentUserInfo").then(data=>{
//   console.log(data)
//   new Vue({
//     router,
//     store,
//     render: h => h(App)
//   }).$mount('#app')
// },err=>{
//   console.log(err.response)
//   let vm = new Vue()
//   if(err && err.response.status===401){  //无权限
//     window.location.href = process.env.VUE_APP_APIURL+"Account/Authentication"; //跳转cas认证
//   }else if(err){
//     vm.$message.error(err.response.statusText);
//   }
// })
