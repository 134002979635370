














































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MeetingModule from '@/store/modules/meeting';
import BaseDataModule from '@/store/modules/base-data';
import Anchor from '@/views/components/Anchor.vue';
import Collective from '@/views/components/Collective.vue';
import CallBackStaff from '@/store/entities/callback-staff';
import KeyValuePair from '@/store/entities/key-value-pair';

@Component({
  components: {
    Anchor,
    Collective
  }
})
export default class AddMidway extends Vue {
  @Prop({ default: false }) isShow!: boolean;
  @Prop({ type: Number }) id!: number;

  searchLoading: boolean = false;
  saveLoading: boolean = false;
  showCollective: boolean = false;
  showInformer: boolean = false;

  ruleModel: any = {};
  attendees: KeyValuePair[] = [];
  informers: KeyValuePair[] = [];

  attendeeIds: number[] = [];
  informersIds: number[] = [];
  searchList: CallBackStaff[] = [];
  searchRecordList: CallBackStaff[] = [];

  get currentInfo() {
    return MeetingModule.currentInfo;
  }

  get currentUser() {
    return BaseDataModule.currentUser;
  }

  async remoteMethod(q: string) {
    this.searchLoading = true;
    await BaseDataModule.getEmployeeListBySearch({ searchKeyword: q })
      .then((res) => {
        this.searchList = res.result.map((item: CallBackStaff) => {
          let o: CallBackStaff = {
            employeeID: item.employeeID,
            employeeName: item.employeeName,
            positionName: item.positionName
          };
          this.pushSearchRecordList(o);
          return o;
        });
      })
      .finally(() => {
        this.searchLoading = false;
      });
  }

  async save() {
    (this.$refs.ruleForm as any).validate((valid: boolean) => {
      if (valid) {
        this.saveLoading = true;
        this.$confirm('确认调整参会人员？', '温馨提示', {
          confirmButtonText: '选择无误,确认提交',
          cancelButtonText: '我再想想',
          type: 'warning'
        })
          .then(async () => {
            await MeetingModule.adjustParticipants({
              id: this.id,
              attendees: this.attendees,
              informers: this.informers
            }).then(async (res) => {
              await MeetingModule.get({ id: this.id });
              this.$emit('update:isShow', false);
            });
          })
          .catch(() => {
            return;
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    });
  }

  pushSearchList(item: CallBackStaff) {
    let _index = this.searchList.findIndex((x) => x.employeeID == item.employeeID);
    if (_index <= -1) {
      this.searchList.push(item);
    }
  }

  pushSearchRecordList(item: CallBackStaff) {
    let _index = this.searchRecordList.findIndex((x) => x.employeeID == item.employeeID);
    if (_index <= -1) {
      this.searchRecordList.push(item);
    }
  }

  attendeeChange(val: number[]) {
    let selectedAll = this.searchRecordList.filter((x) => val.indexOf(x.employeeID) > -1);
    this.attendees = selectedAll.map((x) => ({
      key: x.employeeID,
      value: x.employeeName
    }));
  }

  informerChange(val: number[]) {
    let selectedAll = this.searchRecordList.filter((x) => val.indexOf(x.employeeID) > -1);
    this.informers = selectedAll.map((x) => ({
      key: x.employeeID,
      value: x.employeeName
    }));
  }

  attendeeCallback(value: CallBackStaff[]) {
    value.forEach((item) => {
      this.pushSearchList(item);
      this.pushSearchRecordList(item);

      if (this.attendeeIds.indexOf(item.employeeID) <= -1) {
        this.attendeeIds.push(item.employeeID);
        /*回调后触发change事件*/
        this.attendeeChange(this.attendeeIds);
      }
    });

    (this.$refs.ruleForm as any).validateField('attendeeProp');
  }

  informerCallback(value: CallBackStaff[]) {
    value.forEach((item) => {
      this.pushSearchList(item);
      this.pushSearchRecordList(item);

      if (this.informersIds.indexOf(item.employeeID) <= -1) {
        this.informersIds.push(item.employeeID);
        /*回调后触发change事件*/
        this.informerChange(this.informersIds);
      }
    });
  }

  emptyAttendee() {
    this.attendeeIds.splice(0);
    this.attendees.splice(0);
  }

  emptyInformers() {
    this.informersIds.splice(0);
    this.informers.splice(0);
  }

  open() {
    Object.assign(this.attendees, this.currentInfo.attendees);
    Object.assign(this.informers, this.currentInfo.informers);

    this.attendeeIds = this.currentInfo.attendees.map((x) => {
      let o = {
        employeeID: x.key,
        employeeName: x.value,
        positionName: x.positionName
      };
      this.pushSearchList(o);
      this.pushSearchRecordList(o);
      return x.key;
    });

    this.informersIds = this.currentInfo.informers.map((x) => {
      let o = {
        employeeID: x.key,
        employeeName: x.value,
        positionName: x.positionName
      };
      this.pushSearchList(o);
      this.pushSearchRecordList(o);
      return x.key;
    });
  }

  close() {
    this.$emit('update:isShow', false);
  }

  attendeePropVerify(rule: any, value: string, callback: any) {
    if (this.attendeeIds.length <= 0) callback(new Error('请选择参会人'));
    else callback();
  }

  verifyRules: any = {
    attendeeProp: {
      required: true,
      trigger: 'change',
      validator: this.attendeePropVerify
    }
  };
}
