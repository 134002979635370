import { StateEnum } from './state-enum'
import { CallApiStaff } from './callback-staff'

export default class MeetingInfo {
    id: number = 0;
    name: string = "";
    subscriberId: number = 0;
    subscriberName: string = "";
    callerId: number | null = null;
    callerName: string = "";
    positionName: string = "";
    approvalOrgID: number = 0;
    approvalOrgName: string = "";
    /**会议类型*/
    meetingType: number = 0;
    /*单次会议日期*/
    singleDate: string = "";
    repeatStartDate: string = "";
    repeatEndDate: string = "";
    beginTime: string = "";
    endTime: string = "";
    content: string = "";
    status: any = StateEnum.Draft;
    statusDesc: string = "";
    meetingMode: number = 0;
    cycleRepeatMode: number = 0;
    repeatIntervalDays: string = "";
    weeks: Array<number> = [];
    desc: string = "";
    lastModifierUserId: number = 0;
    actualEndTime: string = "";

    /**参会基地多选*/
    joinOrgID: Array<number> = [];
    /**参会基地单选*/
    joinSingleOrgID: number = 0;
    ckIds: Array<number> = [];

    /*关联会议室*/
    relationRooms: Array<any> = [];
    /*关联参会人*/
    attendees: Array<CallApiStaff> = [];
    /*关联知会人*/
    informers: Array<CallApiStaff> = [];
}