








































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { StateEnum } from '@/store/entities/state-enum'
import MeetingModule from '@/store/modules/meeting'
import BaseDataModule from '@/store/modules/base-data'
import AddMidway from '@/views/components/AddMidway.vue'

@Component({ components: { AddMidway } })
export default class EventView extends Vue {
  @Prop({ default: false }) isShow!: boolean;
  @Prop({ type: Number }) id!: number;

  isLoading: boolean = true;
  isFinishing: boolean = false;
  isCanceling: boolean = false;
  showAddMidway: boolean = false;

  get currentUser() {
    return BaseDataModule.currentUser;
  }

  /*调整参会人按钮显示*/
  get isShowAddMidway() {
    let _now = new Date();
    let _startTime = new Date(`${this.currentInfo.singleDate} ${this.currentInfo.beginTime}`);

    if (_now >= _startTime) {
      return false;
    } else if (this.currentInfo.status != StateEnum.Pending && this.currentInfo.status != StateEnum.Adopt) {
      return false;
    } else if (this.currentUser.isAdmin) {
      /* 是否 管理员 */
      return true;
    } else if (this.currentInfo.subscriberId == Number(this.currentUser.employeeID)) {
      /* 是否 本人预定 */
      return true;
    } else if (this.currentInfo.callerId == this.currentUser.employeeID) {
      /* 是否 主持人 */
      return true;
    }

    return false;
  }

  /*复制按钮显示*/
  get isShowCopy() {
    let path = this.$route.path;
    return path === "/";
  }

  /*提前结束按钮显示*/
  get isShowFinish() {
    //必须是进行中的会议
    let start = new Date(`${this.currentInfo.singleDate} ${this.currentInfo.beginTime}`);
    let end = new Date(`${this.currentInfo.singleDate} ${this.currentInfo.endTime}`);
    let now = new Date();

    let _currentUser = BaseDataModule.currentUser;

    if (now < start) {
      return false;
    } else if (now > end) {
      return false;
    } else if (this.currentInfo.status != StateEnum.Adopt) {
      //必须是审批通过的会议
      return false;
    } else if (_currentUser.isAdmin) {
      /* 是否 管理员 */
      return true;
    } else if (this.currentInfo.subscriberId == Number(_currentUser.employeeID)) {
      /* 是否 本人预定 */
      return true;
    } else if (this.currentInfo.callerId == _currentUser.employeeID) {
      /* 是否 主持人 */
      return true;
    }

    return false;
  }

  /*实际结束时间显示*/
  get isShowActualEndTime() {
    return this.currentInfo.status == StateEnum.Finish;
  }

  /*提交按钮显示*/
  get isShowSubmit() {
    /* 草稿才能提交*/
    if (this.currentInfo.status == StateEnum.Draft) {
      let _currentUser = BaseDataModule.currentUser;
      /* 是否 管理员 */
      if (_currentUser.isAdmin) return true;
      /* 是否 本人 */
      if (this.currentInfo.subscriberId == Number(_currentUser.employeeID)) return true;

      return false;
    }

    return false;
  }

  /*取消会议按钮显示*/
  get isShowCancel() {
    /*先决条件 草稿、待审批、审批通过*/
    let isMatchState = (this.currentInfo.status == StateEnum.Draft ||
      this.currentInfo.status == StateEnum.Pending ||
      this.currentInfo.status == StateEnum.Adopt);

    if (!isMatchState) return false;
    let _currentUser = BaseDataModule.currentUser;
    /* 是否 管理员 */
    if (_currentUser.isAdmin) return true;
    /* 是否 本人 */
    if (this.currentInfo.subscriberId == Number(_currentUser.employeeID)) return true;

    return false;
  }

  get currentInfo() {
    return MeetingModule.currentInfo;
  }

  get meetingDate() {
    if (!this.currentInfo.repeatStartDate) {
      return this.currentInfo.singleDate;
    } {
      return `${this.currentInfo.repeatStartDate} ~ ${this.currentInfo.repeatEndDate}`;
    }
  }

  /**结束会议*/
  finishMeeting() {
    this.isFinishing = true;

    this.$confirm("确定提前结束会议？", "提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await MeetingModule.finishMeeting({ meetingId: this.currentInfo.id }).then((res) => {
        this.$message.success("操作成功");
        this.$emit("refreshData");
        this.close();
      }).finally(() => {
        this.isFinishing = false;
      });
    }).catch(() => {
      this.isFinishing = false;
    });
  }

  copy() {
    this.close();
    this.$emit("copy");
  }

  addMidway() {
    this.showAddMidway = true;
  }

  async open() {
    await MeetingModule.get({ id: this.id });
    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  }

  async submitDraft() {
    this.$confirm("确认提交审批？", "提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await MeetingModule.submitDraft({ meetingId: this.currentInfo.id }).then((res) => {
        this.$message.success("操作成功");
        this.$emit("refreshData");
        this.close();
      }).finally(() => {
        this.isLoading = false;
      });
    }).catch(() => {
      this.isLoading = false;
    });
  }

  async cancelMeeting() {
    this.isCanceling = true;

    this.$confirm("确认取消会议？", "提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await MeetingModule.cancelMeeting({ meetingId: this.currentInfo.id }).then((res) => {
        this.$message.success("操作成功");
        this.$emit("refreshData");
        this.close();
      }).finally(() => {
        this.isCanceling = false;
      });
    }).catch(() => {
      this.isCanceling = false;
    });
  }

  close() {
    this.isLoading = true;
    this.$emit("update:isShow", false);
  }
}
